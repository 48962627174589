<template>
	<footer class="footer">
		<Container class="mx-auto">
			<div class="footer-inner">
				<div class="author-card-container">
					<div class="author-logo">
						<img
							src="/images/logo.svg"
							alt="Timothy Sykes Logo"
							width="186"
							height="26"
							class="site-logo"
							loading="lazy"
						/>
					</div>
					<div class="author-card">
						<div class="author-photo">
							<img
								src="/images/Tim-Sykes-Headshot-Footer.webp"
								width="160"
								height="160"
								alt="Tim Sykes"
								style="width: 159px; height: 159px"
								loading="lazy"
							/>
						</div>
						<div class="author-text">
							<p>
								I became a self-made millionaire by the age of 21, trading thousands of
								Penny Stocks - yep you read that right, Penny Stocks. You may have
								heard...
							</p>
							<NuxtLink
								to="/blog/author/tim-sykes/"
								class="author-link"
							>
								Read more about Timothy Sykes...
							</NuxtLink>
						</div>
					</div>
					<div class="author-social">
						<NuxtLink
							href="https://www.facebook.com/timsykesfans/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src="/images/facebook.svg"
								alt="Facebook"
								width="28"
								height="28"
								class="social-icon facebook-icon"
								loading="lazy"
							/>
						</NuxtLink>
						<NuxtLink
							href="https://www.youtube.com/channel/UCoSG43KFjTe0trsteSQ46Ng"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src="/images/youtube.svg"
								alt="YouTube"
								width="28"
								height="28"
								class="social-icon youtube-icon"
								loading="lazy"
							/>
						</NuxtLink>
						<NuxtLink
							href="https://www.instagram.com/timothysykes/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src="/images/instagram.svg"
								alt="Instagram"
								width="28"
								height="28"
								class="social-icon instagram-icon"
								loading="lazy"
							/>
						</NuxtLink>
						<NuxtLink
							href="https://twitter.com/timothysykes"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src="/images/twitterX.svg"
								alt="X/Twitter"
								width="28"
								height="28"
								class="social-icon twitter-icon"
								loading="lazy"
							/>
						</NuxtLink>
					</div>
				</div>
				<div class="author-info-list-container">
					<div class="author-info-list-wrapper">
						<h4 class="author-info-list-title">Learn</h4>
						<ul class="author-info-list">
							<li class="author-info-list-item">
								<nuxt-link to="/blog/">Blog</nuxt-link>
							</li>
							<li class="author-info-list-item">
								<nuxt-link to="/news/">Stock News</nuxt-link>
							</li>
							<li class="author-info-list-item">
								<nuxt-link to="/blog/best-broker-for-day-trading/">
									Trading Guides
								</nuxt-link>
							</li>
							<li class="author-info-list-item">
								<nuxt-link to="/blog/penny-stocks-to-watch/">Stocks</nuxt-link>
							</li>
							<li class="author-info-list-item">
								<nuxt-link to="/blog/different-stock-sectors/">Resources</nuxt-link>
							</li>
							<li class="author-info-list-item">
								<nuxt-link to="/blog/author/tim-sykes/">About</nuxt-link>
							</li>
						</ul>
					</div>
					<div class="author-info-list-wrapper">
						<h4 class="author-info-list-title">Tim’s Important Resources</h4>
						<ul class="author-info-list">
							<li class="author-info-list-item big">
								<nuxt-link to="/penny-stocking-chapter-01/">
									Penny Stocking 101: How To Get Started with Penny Stocks
								</nuxt-link>
							</li>
							<li class="author-info-list-item big">
								<nuxt-link
									to="/blog/my-secret-formula-for-finding-penny-stocks-pre-spike/"
								>
									Which Penny Stocks To Buy
								</nuxt-link>
							</li>
							<li class="author-info-list-item big">
								<nuxt-link to="/blog/how-to-invest-in-penny-stocks/">
									How To Invest In Penny Stocks
								</nuxt-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="sub-navigation-container">
				<div class="sub-navigation-menu">
					<div class="copyright-and-menu">
						<p class="copyright">
							&copy; {{ new Date().getFullYear() }} Millionaire Media, LLC. All Rights
							Reserved.
						</p>

						<nuxt-link
							href="https://www.traderguard.com/"
							target="_blank"
							class="mx-auto"
						>
							<img
								src="/images/dmca-protection.png"
								class="h-[40px] objeect-cover"
								alt="DMCA Protection"
							/>
						</nuxt-link>
						<ul class="menu-list lg:ml-auto">
							<li>
								<nuxt-link to="/">Home</nuxt-link>
							</li>
							<li>
								<nuxt-link
									rel="nofollow"
									target="_blank"
									href="https://millionairepub.everflowclient.io/affiliate/signup"
								>
									Partner with us
								</nuxt-link>
							</li>
							<li>
								<nuxt-link
									rel="nofollow"
									href="https://profit.ly/profiding?aff=12"
								>
									Chatroom
								</nuxt-link>
							</li>
							<li>
								<nuxt-link to="/contact/">Contact</nuxt-link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</Container>
	</footer>
	<Disclaimer
		:site-url="props.siteUrl"
		:class="{
			hasSwipe: showSwipe && routeCheck
		}"
	/>

	<ClientOnly>
		<div class="fixed bottom-0 z-[999]">
			<SlideUpComponent
				v-if="showInMobile"
				:on-swipe-close="() => (showSwipe = false)"
				swipe-title="to Start Trading NOW"
				:class="store.showCookieConsentBanner ? 'hasCookieConsent' : ''"
			/>
			<DesktopCta
				v-else-if="showInDesktop"
				:on-swipe-close="() => (showSwipe = false)"
				:class="store.showCookieConsentBanner ? 'hasCookieConsent' : ''"
			/>

			<CookieConsent />
		</div>
	</ClientOnly>
</template>
<script setup>
const $route = useRoute();
const windowWidth = ref(null);
const showSwipe = ref(true);
const hasCookie = ref(false);
const props = defineProps({
	siteUrl: {
		type: String,
		required: false,
		default: ""
	}
});

const getCookie = (cname) => {
	const name = cname + "=";
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
};

const routeCheck = computed(() => {
	return (
		$route.path !== "/contact/" && $route.path !== "/millionaire-challenge/"
	);
});
const showInDesktop = computed(() => {
	if (process.client) {
		return routeCheck.value && window.innerWidth > 768 && showSwipe.value;
	}
	return false;
});

const showInMobile = computed(() => {
	if (process.client) {
		return routeCheck.value && window.innerWidth <= 768 && showSwipe.value;
	}
	return false;
});

const modalSwipeDisplayCheck = (cookie) => {
	// TODO - check if other CTA is active or not, may require introducing delay before displaying element
	return cookie !== "true";
};

onMounted(() => {
	switch ($route.path) {
		case "/trading-hw-1/":
		case "/trading-hw-2/":
		case "/trading-hw-3/":
		case "/trading-hw-4/":
		case "/trading-hw-5/":
		case "/trading-hw-bonus/":
		case "/millionaire-challenge/":
			showSwipe.value = false;
			break;
		default:
			showSwipe.value = true;
	}

	if (process.client) {
		modalSwipeDisplayCheck(getCookie("mdsphd"));
		showSwipe.value = modalSwipeDisplayCheck(getCookie("mdsphd"));
		hasCookie.value = modalSwipeDisplayCheck(getCookie("mdsphd"));

		windowWidth.value = window.innerWidth;

		const handleResize = () => {
			windowWidth.value = window.innerWidth;
		};

		window.addEventListener("resize", handleResize);

		onBeforeUnmount(() => {
			window.removeEventListener("resize", handleResize);
		});
	}
});
</script>
<style lang="scss" scoped>
@import "../assets/utils.scss";

.footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #091538;
	padding: 64px 0;
	position: relative;
	z-index: 99;
	width: 100vw;
}

.footer-inner {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	align-self: stretch;
	column-gap: 40px;
	flex-shrink: 0;

	@media (min-width: 1100px) {
		justify-content: center;
	}
	@media (min-width: 1000px) {
		flex-wrap: nowrap;
	}
}

.author-logo {
	margin-bottom: 38px;
}

.author-card {
	display: flex;
	align-items: center;
	gap: 24px;
	align-self: stretch;
}

.author-text {
	color: var(--neutrals-slate-300, #cbd5e1);
	font-family: Rubik;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	/* 29.6px */
}

.author-link {
	color: var(--primary-blue-500, #3b82f6);
	font-family: Rubik;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	letter-spacing: 0.18px;

	&:hover {
		text-decoration: underline;
		color: $primary-500;
	}
}

.author-social {
	margin-top: 16px;
	display: flex;
	width: 159px;
	justify-content: center;
	align-items: center;
	gap: 24px;
}

.social-icon {
	width: 27px;
	height: 26.836px;
	flex-shrink: 0;
}

.author-info-list-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 80px;
	width: 100%;
	@media (min-width: 600px) {
		margin-top: 64px;
	}
	@media (min-width: 1000px) {
		flex-wrap: nowrap;
		margin-top: 0;
		display: inline-flex;
		margin-left: auto;
		width: auto;
		gap: 40px;
	}
	@media (min-width: 1300px) {
		gap: 80px;
	}
}

.author-info-list {
	display: flex;
	width: 160px;
	flex-direction: column;
	align-items: flex-start;
	gap: 27px;
}

.author-info-list-title {
	color: var(--primary-blue-300, #93c5fd);
	font-family: Fira Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	/* 28.8px */
	margin-bottom: 27px;
}

.author-info-list-item {
	align-self: stretch;
	color: var(--neutrals-slate-300, #cbd5e1);
	font-family: Rubik;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%;
	/* 29.6px */
}

.big {
	width: 347px;
}

.sub-navigation-container {
	padding-top: 20px;
	border-top: 1px solid #0a0e1d;
	display: flex;
	flex-direction: column;
	margin-top: 64px;
	width: 100%;
}

.sub-navigation-menu {
	color: var(--neutrals-slate-500, #64748b);
	font-family: Rubik;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	/* 24px */
	width: 100%;
	order: 3;
}

.copyright-and-menu {
	display: flex;
	align-items: center;
	gap: 20px;
}

.menu-list {
	list-style: none;
	padding: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 28px;
	white-space: nowrap;
}

.menu-list li {
	display: inline-block;
	margin-right: 20px;
}

.menu-list li:last-child {
	margin-right: 0;
}

.twitter-icon {
	width: 32px;
	height: 32px;
}

.author-text {
	@include breakpoint(lg) {
		width: 341px;
	}
}

@media (max-width: 992px) {
	.footer {
		display: flex;
		padding: 40px 0;
		align-items: center;
		gap: 10px;
		align-self: stretch;
		width: auto;
		height: auto;
	}

	.footer-inner {
		padding: 0px;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		width: auto;
		height: auto;
		gap: 48px;
	}

	.author-logo {
		display: flex;
		width: auto;
		align-items: center;
		gap: 16px;
	}

	.author-card {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 24px;
		align-self: stretch;
		@include breakpoint(lg) {
			gap: 48px;
		}
	}

	.author-info-list {
		width: auto;
		align-items: center;
		gap: 16px;
	}

	.author-info-list-item {
		width: auto;
		text-align: center;
	}

	.author-social {
		order: 2;
	}

	.social-icon {
		width: auto;
	}

	.author-info-list-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 10px;
	}

	.author-info-list-wrapper {
		width: calc(50% - 5px);
		flex-basis: calc(50% - 5px);
	}

	.author-info-list-item {
		text-align: start;
	}

	.sub-navigation-container {
		width: 100%;
		padding-top: 10px;
		border-top: 1px solid #0a0e1d;
	}

	.copyright-and-menu {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 48px;
		align-self: stretch;
	}

	.copyright {
		align-self: stretch;
		width: 100%;
		text-align: left;
		white-space: break-spaces;
	}

	.menu-list {
		width: 100%;
		flex-wrap: wrap;
		justify-content: center;
	}

	.menu-list li {
		margin-right: 0;
	}
}

.author-photo img {
	min-width: 160px;
	min-height: 160px;
}
@media (max-width: 600px) {
	.copyright {
		text-align: center;
		width: 100%;
	}

	.sub-navigation-container {
		margin-top: 32px;
	}
}

@media (min-width: 1000px) and (max-width: 1200px) {
	.author-card-container {
		max-width: 460px;
	}
}
</style>
